





























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { PageNum } from "@/tool/interface-index";
import { ExamStudent, SchoolGroup, SchoolSchoolGroup } from "@/tool/_class";
import {
  deleteExamStudent,
  getExamStudentList,
  deleteProjectExamStudent,
} from "@/api/examStudent";
import {
  getSchoolCascadeList,
  getSchoolList,
  getSchoolGroupList,
  postSchoolGroup,
  deleteSchoolGroup,
  postSchoolSchoolGroup,
  deleteSchoolSchoolGroup,
} from "@/api/school";
import { getExamProjectList } from "@/api/examProject";
import { ExamProject } from "@/tool/interface-index";
import * as _ from "lodash";
import { exportJson2Excel } from "@/utils/excel";
import { formatJson } from "@/utils";
import XLSX from "xlsx";
import { Tree } from "element-ui";
import { importExamStudent } from "@/api/examStudent";
import ExamStudentEditor from "./exam-student-template-editor.vue";
import SchoolGroupEditor from "./components/school-group-editor.vue";
import { UserModule } from "@/store/modules/user";
import { MessageBoxInputData, MessageBoxData } from "element-ui/types/message-box";
import SplitPane from 'vue-splitpane';

const TemplateExamProjectId = "000";
const UnGroupId = "000";

interface ImportExamStudent {
  examAccount: string;
  studentName: string;
  schoolId?: string;
  schoolName: string;
  classId?: string;
  className: string;
  sex: string;
  photoFileName: string;
  photoFileUrl?: string;
}

@Component({
  name: "ExamStudentListPage",
  components: {
    cardBox,
    pagination,
    ExamStudentEditor,
    SchoolGroupEditor,
    SplitPane
  },
  methods: {
    resize() {
      //
    }
  }
})
export default class extends Vue {
  private activeTabName: string = "treeGroup";
  private activeCollapseName: string = "";
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private tableData: Array<ExamStudent> = [];

  private filterText: string = "";

  @Watch("filterText")
  private handleFilterTextChange(val: string) {
    (this.$refs.tree as any).filter(val)
  }

  private query: {
    keyWords: string;
    cascadeExamSchoolId: string[];
    examProjectId: string;
  } = {
      cascadeExamSchoolId: [],
      keyWords: "",
      examProjectId: TemplateExamProjectId,
      //examState: string
    };

  private currentSchoolName: string = "";

  private examStateOptions: any[] = [];

  private schools: any[] = [];
  private schoolGroups: SchoolGroup[] = [
    /*
    {
      schoolGroupId: "000",
      schoolGroupName: "未分组",
      userId: "",
      schools: [],
    },
    */
  ];

  private areas(schools: SchoolSchoolGroup[]) {
    return _.uniqWith(
      schools.map((item) => {
        return {
          areaId: item.areaId,
          areaName: item.areaName || item.areaId,
        };
      }),
      (arrVal, othVal) => {
        return arrVal.areaId == othVal.areaId;
      }
    );
  }

  private queryStudents() {
    this.getExamStudentListData();
  }

  private handleTabClick(tabName: string) {
    switch (tabName) {
      case "treeGroup": {
        break;
      }
      case "myGroup": {
        break;
      }
    }
  }

  filterNode(value: string, data: any) {

    if (!value) return true;

    return data.keyValue.indexOf(value) !== -1;
  }

  private deleteData(examStudentId: string) {
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        deleteExamStudent(examStudentId).then((res: any) => {
          if (res.code == 0) {
            this.getExamStudentListData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        });
      })
      .catch(() => { });
  }

  private loading: boolean = false;
  private async getExamStudentListData() {
    try {
      this.loading = true;
      this.tableData = [];
      if (!this.query.cascadeExamSchoolId.length) {
        return;
      }
      //
      const res = await getExamStudentList({
        pageSize: this.pageNum.pageSize,
        curPage: this.pageNum.curPage,
        keyWords: this.query.keyWords,
        examSchoolId: _.last(this.query.cascadeExamSchoolId) || "",
        examProjectId: this.query.examProjectId || TemplateExamProjectId,
        examType: "mockExamMode",
      });
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
    } finally {
      this.loading = false;
    }
  }
  private upDataPage() {
    this.getExamStudentListData();
  }

  private exportExamStudent(
    index: number,
    examStudents: any[],
    fileName: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const tHeader = [
        "考号",
        "姓名",
        "性别",
        "学校",
        "班级",
        "考点名称",
        "相片",
      ];
      const filterVal = [
        "examAccount",
        "examStudentName",
        "sex",
        "schoolName",
        "className",
        "examSchoolName",
        "photoFileName",
      ];
      const data = formatJson(filterVal, examStudents);
      setTimeout(() => {
        exportJson2Excel(tHeader, data, fileName);
        resolve();
      }, index * 300);
    });
  }

  private exportLoading: boolean = false;
  private async doExportExamStudentClick() {
    try {
      this.exportLoading = true;
      //
      let examProjectName: string = "000";
      //
      let p: any[] = [];
      let students: any[] = [];
      let examSchools: any[] = [];
      const resp = await getSchoolList({ curPage: 1, pageSize: 999 });
      examSchools.push(...resp.data.items);
      //
      const resp1 = await getExamStudentList({
        pageSize: this.pageNum.pageSize,
        curPage: 1,
        keyWords: this.query.keyWords,
        examSchoolId: _.last(this.query.cascadeExamSchoolId) || "",
        examProjectId: this.query.examProjectId || TemplateExamProjectId,
        examType: "mockExamMode",
      });
      students.push(...resp1.data.items);
      //
      for (let i = 2; i <= resp1.data.totalPage; i++) {
        p.push(
          getExamStudentList({
            pageSize: this.pageNum.pageSize,
            curPage: i,
            keyWords: this.query.keyWords,
            examSchoolId: _.last(this.query.cascadeExamSchoolId) || "",
            examProjectId: this.query.examProjectId || TemplateExamProjectId,
            examType: "mockExamMode",
          })
        );
      }
      const resps = await Promise.all(p);
      for (let i = 0; i < resps.length; i++) {
        students.push(...resps[i].data.items);
      }
      //
      let p1: any[] = [];
      let x: number = 0;
      for (let i = 0; i < examSchools.length; i++) {
        const filterStudents: any[] = students
          .filter((student) => {
            return student.examSchoolId == examSchools[i].schoolId;
          })
          .map((student) => {
            return _.merge(student, {
              examSchoolName: examSchools[i].schoolName,
            });
          });
        if (filterStudents.length > 0) {
          x++;
          console.log(
            `export ${x} ${examSchools[i].schoolName}: ${filterStudents.length}人`
          );
          p1.push(
            this.exportExamStudent(
              x,
              filterStudents,
              `${examProjectName}_${x}_${examSchools[i].schoolName}`
            )
          );
        }
      }
      await Promise.all(p1);
      this.$message({
        type: "success",
        message: `导出完成，共${x}个文件，请核查是否有缺失。`,
      });
    } catch (error: any) {
      this.$message({
        type: "error",
        message: `导出失败，${error.message || error.msg || ""}。`,
      });
    } finally {
      this.exportLoading = false;
    }
  }

  private async doClearStudentsClick() {
    if (!this.query.cascadeExamSchoolId.length) {
      this.$message({
        type: "warning",
        message: `请选选择学校。`,
      });
      return;
    }
    await this.$confirm(
      `重置"${this.currentSchoolName}"报名库, 是否继续?`,
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    );

    await deleteProjectExamStudent({
      examProjectId: TemplateExamProjectId,
      examSchoolId: this.query.cascadeExamSchoolId[0],
    });
    await this.getExamStudentListData();
  }

  private handleSchoolGroupEditorClose(
    confirmed: boolean,
    school: SchoolSchoolGroup,
    schoolGroup: SchoolGroup
  ) {
    if (!confirmed) {
      return;
    }
    const group = this.schoolGroups.find((item) => {
      return item.schoolGroupId == schoolGroup.schoolGroupId;
    })!;
    for (let i = 0; i < this.schoolGroups.length; i++) {
      const idx = this.schoolGroups[i].schools.findIndex((item) => {
        return item.schoolId == school.schoolId;
      });
      if (idx >= 0) {
        const tmp = _.merge(this.schoolGroups[i].schools[idx], school);
        this.schoolGroups[i].schools.splice(idx, 1);
        group.schools.unshift(tmp);
        break;
      }
    }
    this.activeCollapseName = group.schoolGroupId;
  }

  private handleExamStudentEditorClose(
    confirmed: boolean,
    examStudent: ExamStudent
  ) {
    if (!confirmed) {
      return;
    }
    const idx = this.tableData.findIndex((item: ExamStudent) => {
      return item.examStudentId == examStudent.examStudentId;
    });
    if (idx >= 0) {
      _.merge(this.tableData[idx], examStudent);
    } else {
      this.tableData.unshift(examStudent);
    }
  }
  private doNewExamStudentClick() {
    const examStudent = new ExamStudent();
    examStudent.examProjectId = TemplateExamProjectId;
    examStudent.examSchoolId = this.query.cascadeExamSchoolId[0];
    (this.$refs.examStudentEditor as ExamStudentEditor).show(examStudent);
  }

  private doEditExamStudentClick(examStudent: ExamStudent) {
    (this.$refs.examStudentEditor as ExamStudentEditor).show(examStudent);
  }

  private async doAddOrSetSchoolGroupClick(group: SchoolGroup) {
    try {
      const isNew: boolean = group.schoolGroupId == UnGroupId;
      if (isNew) {
      }

      const inputData: MessageBoxData = await this.$prompt(
        "请输入分组名",
        isNew ? "添加分组" : "修改组名",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValue: isNew ? "" : group.schoolGroupName,
          inputValidator: (value: string) => {
            if (!value) {
              return "请输入组名。";
            }
            return true;
          },
        }
      );
      let schoolGroup: SchoolGroup = new SchoolGroup();
      schoolGroup.schoolGroupName = (inputData as MessageBoxInputData).value;
      if (isNew) {
        const MD5 = require("md5.js");
        let md5: string = new MD5()
          .update(`${UserModule.userId}-${schoolGroup.schoolGroupName}`)
          .digest("hex");
        schoolGroup.schoolGroupId = md5;
      } else {
        _.merge(schoolGroup, _.pick(group, ["schoolGroupId", "userId"]));
      }
      await postSchoolGroup(schoolGroup);
      if (isNew) {
        this.schoolGroups.unshift(schoolGroup);
      } else {
        group.schoolGroupName = schoolGroup.schoolGroupName;
      }
      this.activeCollapseName = schoolGroup.schoolGroupId;
    } catch (error) {
    } finally {
    }
  }

  private async doDeleteSchoolGroupClick(group: SchoolGroup) {
    try {
      await this.$confirm(
        `删除分组“${group.schoolGroupName}”, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      let i: number = 0;
      while (i < group.schools.length) {
        await deleteSchoolSchoolGroup(group.schools[i].schoolSchoolGroupId);
        const school = group.schools.pop()!;
        school.schoolGroupId = UnGroupId;
        this.schoolGroups[0].schools.push(school);
      }
      await deleteSchoolGroup(group.schoolGroupId);
    } catch (error) {
      if (typeof error == "string" && error == "cancel") {
        return;
      }
    } finally {
      //
    }
  }

  private doChangeSchoolGroup(school: SchoolSchoolGroup, group: SchoolGroup) {
    (this.$refs.schoolGroupEditor as SchoolGroupEditor).show(
      school,
      this.schoolGroups.map((item) => {
        let tmp: SchoolGroup = new SchoolGroup();
        return _.merge(
          tmp,
          _.pick(item, ["schoolGroupId", "schoolGroupName", "userId"])
        );
      })
    );
  }

  private setActiveSchool(
    school: { schoolId: string; schoolName: string } | null = null
  ) {
    this.query.cascadeExamSchoolId.splice(
      0,
      this.query.cascadeExamSchoolId.length
    );
    this.currentSchoolName = "";
    if (school) {
      this.currentSchoolName = school.schoolName;
      this.query.cascadeExamSchoolId.push(school.schoolId);
      this.pageNum.curPage = 1;
      this.getExamStudentListData();
    } else {
      this.tableData = [];
    }
  }

  private handleNodeClick(data: any, node: any) {
    if (data.dataType == "school") {
      this.setActiveSchool({
        schoolId: data.keyId,
        schoolName: data.keyValue,
      });
    } else {
      this.setActiveSchool();
    }
  }

  private getCascadeItem(keyId: string, cascades: any[]): any {
    let result = undefined;
    cascades.some(function iter(obj) {
      if (obj.keyId == keyId) {
        result = obj;
        return true;
      }
      return Array.isArray(obj.children) && obj.children.some(iter);
    });
    return result;
    /*
    for (let i = 0; i < cascades.length; i++) {
      if (cascades[i].keyId == keyId) {
        return cascades[i]
      }
      if (_.has(cascades[i], 'children')) {
        return this.getCascadeItem(keyId, cascades[i].children);
      }

    }
    */
  }

  private getCascadeSchoolId(schoolId: string): string[] {
    let arr: string[] = [];
    let keyId: string = schoolId;
    do {
      const item = this.getCascadeItem(keyId, this.schools);
      if (item) {
        arr.push(keyId);
        keyId = item.parentKeyId;

        continue;
      }
      break;
    } while (true);
    return arr.reverse();
  }

  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "school": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            cascades.splice(i, 1);
          } else {
            this.removeNullChildren(cascades[i].children);
            i++;
          }
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  private __init() {
    getSchoolCascadeList().then(({ data }) => {
      this.schools = data.items;
      this.removeNullChildren(this.schools);
    });
    getSchoolGroupList({
      mergeSchools: true,
      curPage: 1,
      pageSize: 10000,
    }).then(({ data }) => {
      this.schoolGroups = data.items;
    });
  }
  mounted() {
    //this.getExamStudentListData();
    this.__init();
  }
  activated() {
    this.getExamStudentListData();
  }

  //导入学生名单
  private fileName: string = "";
  private xlxsList: {
    examProjectId: string;
    examSchoolId: string;
    examRoomIds: string[];
    items: ImportExamStudent[];
  } = {
      examProjectId: TemplateExamProjectId,
      examSchoolId: "",
      examRoomIds: [],
      items: [],
    };
  private checkedFields: string[] = [
    "examAccount",
    "studentName",
    //"sex",
    "schoolName",
    "className",
    //"photoFileName",
  ];
  private readXLSX(file: any) {
    let nameSplit = file.name.split(".");
    this.fileName = file.name;
    let format = nameSplit[nameSplit.length - 1];
    if (!["xlsx", "xls"].includes(format)) {
      return false;
    }
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (evt: any) => {
        let data = evt.target.result; // 读到的数据
        let workbook = XLSX.read(data, { type: "binary" });
        let firstSheetName = workbook.SheetNames[0];
        // let worksheet = workbook.Sheets[firstSheetName];
        // this.tableHeader = this.getHeaderRow(worksheet);
        let exlname = workbook.SheetNames[0]; // 取第一张表
        let exl = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
        resolve(exl);
      };
    });
  }

  private handleChangeFile(file: any, fileList: any[]) {
    if (fileList.length <= 0) {
      return;
    }
    let fileName: string = _.replace(
      _.replace(fileList[0].name, ".xlsx", ""),
      ".xls",
      ""
    );
    const names: string[] = fileName.split("_");
    if (this.query.cascadeExamSchoolId.length == 0 && names.length > 0) {
      const idx = this.schools.findIndex((school) => {
        return school.schoolName == names[names.length - 1];
      });
      if (idx >= 0) {
        this.query.cascadeExamSchoolId = this.getCascadeSchoolId(
          this.schools[idx].schoolId
        );
        (this.$refs.tree as Tree).setCurrentKey(this.schools[idx].schoolId);
      }
    }
  }

  private importLoading: boolean = false;
  private fileList: Array<{ name: string; url: string }> = [];
  private async beforeUpload(file: any) {
    //alert("tst");
    try {
      await this.$confirm("导入学生名单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      this.importLoading = true;
      const items: any = await this.readXLSX(file);
      this.xlxsList.items = items.map((item: any, index: number) => {
        let result = {
          examAccount: "",
          studentName: "",
          sex: "",
          schoolName: "",
          className: "",
          photoFileName: "",
        };

        // stu.examStudentName = xlsStudents[i].examStudentName;

        Object.keys(item).forEach((key, index) => {
          switch (key) {
            case "考号": {
              result.examAccount = item[key].toString() || "";
              break;
            }
            case "姓名": {
              result.studentName = item[key].toString() || "";
              break;
            }
            case "性别": {
              result.sex = item[key].toString() || "";
              break;
            }
            case "学校": {
              result.schoolName = item[key].toString() || "";
              break;
            }
            case "班级": {
              result.className = item[key].toString() || "";
              break;
            }
            case "相片": {
              result.photoFileName = item[key].toString() || "";
              break;
            }
          }
        });

        //console.log(`result: ${JSON.stringify(result)}`);

        return result;
      }) as Array<ImportExamStudent>; //读取到的内容
      //
      await this.doImportStudent();
    } finally {
      this.importLoading = false;
      this.fileList.splice(0, this.fileList.length);
    }

  }
  private async doImportStudent() {
    try {
      this.loading = true;
      //数据完整性判断
      for (let i = 0; i < this.xlxsList.items.length; i++) {
        let item = this.xlxsList.items[i];
        if (
          this.checkedFields.some((field) => {
            return _.get(item, field, "") == "";
          })
        ) {
          //alert(JSON.stringify(item));
          throw new Error(`第${i + 1}行数据不完整。`);
        }
      }
      //2023/04/24增加重复考号检测
      const uniqItems = _.unionBy(this.xlxsList.items, "examAccount");
      if (
        this.xlxsList.items.length &&
        this.xlxsList.items.length !== uniqItems.length
      ) {
        throw new Error(`存在重复的考号，请核查后再试。`);
      }
      //开始数据准备及处理 ...
      this.xlxsList.examProjectId = TemplateExamProjectId;
      this.xlxsList.examSchoolId = _.last(
        this.query.cascadeExamSchoolId
      ) as string;
      this.xlxsList.examRoomIds = [];

      const { data } = await importExamStudent(this.xlxsList);
      await this.getExamStudentListData();

      this.loading = false;

      //
      this.$message({
        type: "success",
        message: "添加考生成功",
      });
      this.$emit("closeShowDialog");
    } catch (error: any) {
      this.$message({
        type: "error",
        message: error.message || error.msg || "导入出错。",
      });
    } finally {
      this.loading = false;
    }
  }
}
