















































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { postExamStudent } from "@/api/examStudent";
import { ExamStudent } from "@/tool/_class";
import * as _ from "lodash";
import { uuid } from "@/tool/index";
import { Form } from "element-ui";

const TemplateExamProjectId = "000";

class FormData extends ExamStudent {}

@Component({
  name: "ExamStudentEditor",
})
export default class extends Vue {
  @Prop({ default: "" }) private examProjectId!: string;
  // @Prop({ default: () => TestServeRuntime.default }) private iTestServeRuntime!: TestServeRuntime;
  @Emit("close") onClose(confirmed: boolean, examStudent: ExamStudent) {
    this.dialogVisible = false;
  }
  private formName: string = "examStudentForm";
  private formData: FormData = new FormData();
  private override: boolean = false;
  private dialogVisible: boolean = false;

  private get templateExamProjectId(): string {
    return TemplateExamProjectId;
  }

  private get title(): string {
    if (this.examProjectId == this.templateExamProjectId) {
      return "添加学生";
    }
    return "添加考生";
  }

  private rules: any = {
    examStudentName: [
      {
        required: true,
        message: "请输入姓名",
        trigger: "blur",
      },
    ],
    sex: [
      {
        required: true,
        message: "性别不能为空",
        trigger: "blur",
      },
    ],
    examAccount: [
      {
        required: true,
        message: "请输入考号",
        trigger: "blur",
      },
    ],
    cascadeSchoolId: [
      {
        required: true,
        message: "请选择考生学校",
        trigger: "blur",
      },
    ],
    className: [
      {
        required: true,
        message: "请输入考生班级",
        trigger: "blur",
      },
    ],
  };
  /*
  data() {

  }
  */

  private getCascadeItem(keyId: string, cascades: any[]): any {
    let result = undefined;
    for (let i = 0; i < cascades.length; i++) {
      if (cascades[i].keyId == keyId) {
        result = cascades[i];
        break;
      }
      if (_.has(cascades[i], "children")) {
        let tmp = this.getCascadeItem(keyId, cascades[i].children);
        if (tmp) {
          result = tmp;
        }
      }
    }
    return result;
  }

  mounted() {
    /*
    ExamApi.getSchoolCascadeListCross().then(({ data }) => {
      this.schools = data.items;
      this.removeNullChildren(this.schools);
    });
    */
  }

  show(student: ExamStudent) {
    //
    this.formData = new FormData();
    _.merge(this.formData, student);
    this.dialogVisible = true;
    (this.$refs["examStudentForm"] as Form).resetFields();
  }

  private handleOpen() {
    //暂时只考虑新增
    /*
    this.formData = new FormData();
    this.formData.examStudentId = uuid.v1();
    this.formData.examSchoolId = TestServeRuntime.default.examSchoolId;
    this.formData.examSchoolName = TestServeRuntime.default.examSchoolName;
    this.formData.schoolId = TestServeRuntime.default.examSchoolId;
    this.formData.schoolName = TestServeRuntime.default.examSchoolName;
    this.formData.examProjectId =
      this.examProjectId || TestServeRuntime.default.examProjectId;
      */
  }

  private handleClose() {
    this.onClose(false, this.formData);
  }

  private doCancelClick() {
    /*
    const MD5 = require("md5.js");
    let md5: string = new MD5().update(`a`).digest("hex");
    console.log(`md5 a:${md5}`);
    */
    this.onClose(false, this.formData);
  }

  private async doConfirmClick(formName: string) {
    console.log(`doConfirmClick:${JSON.stringify(this.formData)}`);

    let __valid: boolean = false;
    (this.$refs[formName] as any).validate((valid: boolean) => {
      __valid = valid;
      return valid;
    });

    if (!__valid) {
      this.$message({
        message: "数据完整性校验未通过。",
        type: "warning",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }

    try {
      //this.formData.schoolId = obj1.keyId;
      //this.formData.schoolName = obj1.keyValue;

      let resp: any = undefined;
      if (!this.formData.examStudentId) {
        const MD5 = require("md5.js");
        let md5: string = new MD5()
          .update(`${this.examProjectId}${this.formData.examAccount}`)
          .digest("hex");
        this.formData.examStudentId = md5;
      }

      resp = await postExamStudent(this.formData, this.override);

      this.onClose(true, this.formData);
      this.$message({
        message: "保存成功。",
        type: "success",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
    } catch (error) {
      let resp: any = (error as any)!.response;
      let msg: string = "";
      if (resp) {
        msg = resp!.msg;
      } else {
        // let errorUrl = error.config.url;
        msg = (error as any).message;
        // errorUrl.substring(errorUrl.lastIndexOf("/") + 1) +":" +error.message;
      }
      this.$message({
        message: msg,
        type: "error",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
    }
  }
}
